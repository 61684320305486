<template>
  <div class="row q-col-gutter-x-md q-col-gutter-y-md q-pa-md">
    <div class="col-12">
      <q-table
        @row-click="showQuantityItemDialogEvt"
        title="Items"
        :rows="quantityItems"
        :columns="quantityItemsColumns"
        row-key="description"
        dense
      >
        <template v-slot:top-right>
          <q-input borderless dense debounce="300" v-model="quantityItemFilter" outlined placeholder="Search">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <div class="q-pl-md">
            <q-btn color="green" text-color="white" label="Add" @click="showQuantityItemDialog"/>
          </div>
        </template>
      </q-table>
    </div>
    <q-dialog
      v-model="quantityItemDialog"
    >
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section>
          <div class="text-h6">Item</div>
        </q-card-section>
        <div>
          <q-form
            id="myForm"
            @submit="postQuantityItem()"
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-12">
                <q-input
                  v-model="quantityItem.description"
                  dense
                  outlined
                  label="Name"
                  type="textarea"
                  autogrow
                />
              </div>
            </div>
              <q-separator spaced />
            <div>
              <q-btn label="Submit" type="submit" :disable="quantitySubmitDisable" :loading="quantitySubmitDisable" color="primary"/>
              <q-btn label="Delete" type="reset" color="red" flat class="q-ml-sm" :disable="quantitySubmitDisable" :loading="quantitySubmitDisable" v-touch-hold:3000.mouse="deleteQuantityItem">
                <q-tooltip
                transition-show="flip-right"
                transition-hide="flip-left"
                >
                  Hold for 3 sec.
                </q-tooltip>
              </q-btn>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
    <div class="col-4">
      <q-card class="my-card">
        <q-card-section>
          <div class="text-h6">Unit Types</div>
        </q-card-section>
      <q-card-section>
          <div class="col- q-mb-sm q-gutter-sm">
              <q-btn class="bg-dark" text-color="white" label="Add" @click="postQuantityUnitType()" />
              <q-btn color="red" text-color="white" label="Rename" @click="putQuantityUnitType()"/>
              <q-btn color="red" text-color="white" label="Remove" v-touch-hold:3000.mouse="deleteQuantityUnitType">
                  <q-tooltip
                      transition-show="flip-right"
                      transition-hide="flip-left"
                  >
                  Hold for 3 sec.
                  </q-tooltip>
              </q-btn>
              <q-input v-model="quantityUnitType.unit" label="Edit" :dense="true" />
          </div>
          <q-select
            filled
            virtual-scroll-slice-size="4"
            v-model="quantityUnitType"
            use-input
            label="Unit Types"
            :options="quantityUnitTypes"
            :tabindex="0"
            option-value="id"
            option-label="unit"
          />
        </q-card-section>
      </q-card>
    </div>
    <div class="col-4">
      <q-card class="my-card">
        <q-card-section>
          <div class="text-h6">Measure Types</div>
        </q-card-section>
        <q-card-section>
          <div class="col- q-mb-sm q-gutter-sm">
              <q-btn class="bg-dark" text-color="white" label="Add" @click="postQuantityUsageType()" />
              <q-btn color="red" text-color="white" label="Rename" @click="putQuantityUsageType()"/>
              <q-btn color="red" text-color="white" label="Remove" v-touch-hold:3000.mouse="deleteQuantityUsageType">
                  <q-tooltip
                    transition-show="flip-right"
                    transition-hide="flip-left"
                  >
                    Hold for 3 sec.
                  </q-tooltip>
              </q-btn>
              <q-input v-model="quantityProjectItemUsageType.name" label="Edit" :dense="true" />
          </div>
          <q-select
            filled
            virtual-scroll-slice-size="4"
            v-model="quantityProjectItemUsageType"
            use-input
            label="Usage Types"
            :options="quantityProjectItemUsageTypes"
            :tabindex="0"
            option-value="id"
            option-label="name"
          />
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import genconService from "../services/genconService"
import notifyService from "../services/notifyService"

export default {
  components: [
  ],
  data() {
    return {
      quantityItem: { id: 0, name: 'Select'},
      quantityItems: [],
      quantityItemFilter: '',
      quantityItemDialog: false,
      quantitySubmitDisable: false,
      quantityItemsColumns: [
        { name: 'description', label: 'Name', align: 'left', field: 'description' },
      ],
      quantityUnitType: { id: 0, unit: 'Select' },
      quantityUnitTypes: [],
      quantityProjectItemUsageType: { id: 0, name: 'Select' },
      quantityProjectItemUsageTypes: [],
    }
  },
  created() {
    this.getQuantityItems()
    this.getQuantityUnitTypes()
    this.getQuantityUsageTypes()
  },
  methods: {
    async getQuantityItem(id) {
      await genconService.getQuantityItem(id)
      .then(res => {
        this.quantityItem = res.data
      })
    },
    async getQuantityItems() {
      await genconService.getQuantityItems()
      .then(res => {
        this.quantityItems = res.data
      })
    },
    async postQuantityItem() {
      await genconService.postQuantityItem(this.quantityItem)
      .then(res => {
        this.quantityItem = res.data
        notifyService.notifySuccess("Work saved")
      })
      .catch(error => {
        notifyService.notifyError(error)
      })
      this.getQuantityItems()
    },
    async deleteQuantityItem() {
      await genconService.deleteQuantityItem(this.quantityItem.id)
      .then(function() {
        notifyService.notifySuccess("Entry deleted")
      })
      .catch(error => {
        notifyService.notifyError(error)
      })
      this.getQuantityItems()
    },
    async showQuantityItemDialog() {
      this.quantityItem = {}
      this.quantityItemDialog = true
    },
    async showQuantityItemDialogEvt(evt, row) {
      await this.getQuantityItem(row.id)
      this.quantityItemDialog = true
    },
    async getQuantityUnitTypes() {
      await genconService.getQuantityUnitTypes()
        .then(res => {
          this.quantityUnitTypes = res.data
        })
    },
    async getQuantityUnitType(id) {
      await genconService.getQuantityUnitType(id)
        .then(res => {
          this.quantityUnitType = res.data
        })
        .catch(error => {
          notifyService.notifyError(error)
        })
    },
    async postQuantityUnitType() {
      this.quantityUnitType.id = 0
      await genconService.postQuantityUnitType(this.quantityUnitType)
        .then(res => {
          this.quantityUnitType = res.data
          notifyService.notifySuccess("Entry added")
        })
        .catch(error => {
          notifyService.notifyError(error)
        })
      this.getQuantityUnitTypes()
    },
    async putQuantityUnitType() {
      await genconService.putQuantityUnitType(this.quantityUnitType)
        .then(res => {
          this.quantityUnitType = res.data
          notifyService.notifySuccess("Updated Entry")
        })
        .catch(error => {
          notifyService.notifyError(error)
        })
      this.getQuantityUnitTypes()
    },
    async deleteQuantityUnitType() {
     await genconService.deleteQuantityUnitType(this.quantityUnitType.id)
      .then(function() {
        notifyService.notifySuccess("Entry deleted")
      })
      .catch(error => {
        notifyService.notifyError(error)
      })
      this.getQuantityUnitTypes()
    },
    async getQuantityUsageTypes() {
      await genconService.getQuantityUsageTypes()
        .then(res => {
          this.quantityProjectItemUsageTypes = res.data
        })
    },
    async getQuantityUsageType(id) {
      await genconService.getQuantityUsageType(id)
        .then(res => {
          this.quantityProjectItemUsageType = res.data
        })
        .catch(error => {
          notifyService.notifyError(error)
        })
    },
    async postQuantityUsageType() {
      this.quantityProjectItemUsageType.id = 0
      await genconService.postQuantityUsageType(this.quantityProjectItemUsageType)
        .then(res => {
          this.quantityProjectItemUsageType = res.data
          notifyService.notifySuccess("Entry added")
        })
        .catch(error => {
          notifyService.notifyError(error)
        })
      this.getQuantityUsageTypes()
    },
    async putQuantityUsageType() {
      await genconService.putQuantityUsageType(this.quantityProjectItemUsageType)
        .then(res => {
          this.quantityProjectItemUsageType = res.data
          notifyService.notifySuccess("Entry updated")
        })
        .catch(error => {
          notifyService.notifyError(error)
        })
      this.getQuantityUsageTypes()
    },
    async deleteQuantityUsageType() {
      await genconService.deleteQuantityUsageType(this.quantityProjectItemUsageType.id)
        .then(function() {
          notifyService.notifySuccess("Entry deleted")
        })
        .catch(error => {
          notifyService.notifyError(error)
        })
      this.getQuantityUsageTypes()
    }
  },
}
</script>